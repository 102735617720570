<template>
  <div class='UserDetailScreen-container' v-if="info">
    <el-card header="用户信息" shadow="never">
      <el-descriptions border column="3">
        <el-descriptions-item label="应用">{{ info.uniPlatform }} - {{ info.appName }}
        </el-descriptions-item>
        <el-descriptions-item label="渠道">{{ info.appChannel }}</el-descriptions-item>
        <el-descriptions-item label="adId">{{
          info.attributionAppData ? info.attributionAppData.adId : ''
          }}
        </el-descriptions-item>
        <el-descriptions-item label="昵称">{{ info.name ?info.name : '未知'}}</el-descriptions-item>
        <el-descriptions-item label="状态">{{ info.status ? '正常' : '不可用' }}
          {{ info.anonymous ? '(匿名用戶)' : '' }}
        </el-descriptions-item>
        <el-descriptions-item label="注册时间">{{ info.created }}</el-descriptions-item>
        <el-descriptions-item label="修改时间">{{ info.updated }}</el-descriptions-item>
        <el-descriptions-item label="最后登录时间">{{
          info.loginAt ? dateFormat(info.loginAt) : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item label="登录次数">{{ info.loginCount }}</el-descriptions-item>
        <el-descriptions-item label="手动记账">{{ info.commonBookingCount || 0}}</el-descriptions-item>
        <el-descriptions-item label="AI记账">{{ info.aiBookingCount || 0}}</el-descriptions-item>
        <el-descriptions-item label="定时记账">{{ info.regularBookingCount || 0 }}</el-descriptions-item>
        <el-descriptions-item label="资产账户">{{ info.assetCount || 0 }}</el-descriptions-item>
        <el-descriptions-item label="负债账户">{{ info.liabilitiesCount || 0 }}</el-descriptions-item>
        <el-descriptions-item label="账本数量">{{ info.accountCount || 0 }}</el-descriptions-item>
        <el-descriptions-item label="微信记账">{{ info.wxBookingCount || 0 }}</el-descriptions-item>
        <el-descriptions-item label="自动记账">{{ info.autoBookingCount || 0 }}</el-descriptions-item>
        <el-descriptions-item label="是否已注销">{{ info.banned ? '是' : '否' }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card header="归因数据" shadow="never" v-if="info.attributionAppData" style="margin-top: 15px;">
      <el-descriptions border column="2">
        <el-descriptions-item label="客户端上报" span="2"></el-descriptions-item>
        <el-descriptions-item label="adId" span="1">{{ info.attributionAppData.adId }}</el-descriptions-item>
        <el-descriptions-item label="广告" span="1">{{
          info.attributionAppData.adSetting ? info.attributionAppData.adSetting.name : ''
          }}
        </el-descriptions-item>
        <el-descriptions-item label="oaid" span="1">{{ info.attributionAppData.oaid }}</el-descriptions-item>
        <el-descriptions-item label="oaidMD5" span="1">{{ info.attributionAppData.oaidMD5 }}</el-descriptions-item>
        <el-descriptions-item label="ip" span="1">{{ info.attributionAppData.ip }}</el-descriptions-item>
        <el-descriptions-item label="ipMD5" span="1">{{ info.attributionAppData.ipMD5 }}</el-descriptions-item>
        <el-descriptions-item label="" span="2"></el-descriptions-item>
        <el-descriptions-item label="激活" span="1">
          {{ info.attributionAppData.activeAt ? dateFormat(info.attributionAppData.activeAt) : '无' }}
        </el-descriptions-item>
        <el-descriptions-item label="结果" span="1">{{ info.attributionAppData.activeResponse }}</el-descriptions-item>
        <el-descriptions-item label="" span="2"></el-descriptions-item>
        <template v-if="info.attributionAppData.payAt">
          <el-descriptions-item label="支付上报" span="2"></el-descriptions-item>
          <el-descriptions-item label="支付" span="1">
            {{ info.attributionAppData.payAt ? dateFormat(info.attributionAppData.payAt) : '无' }}
          </el-descriptions-item>
          <el-descriptions-item label="金额" span="1">{{ info.attributionAppData.payAmount / 100 }}
          </el-descriptions-item>
          <el-descriptions-item label="结果" span="1">{{ info.attributionAppData.payResponse }}</el-descriptions-item>
          <el-descriptions-item label="第几天支付" span="2">{{ info.attributionAppData.payDay + 1 }}
          </el-descriptions-item>
        </template>
        <el-descriptions-item label="支付上报" span="2" v-else>无</el-descriptions-item>
        <el-descriptions-item label="" span="2"></el-descriptions-item>
        <template v-if="info.attributionAppData.repairAt">
          <el-descriptions-item label="支付补报" span="2"></el-descriptions-item>
          <el-descriptions-item label="支付" span="1">
            {{ dateFormat(info.attributionAppData.repairAt) }}
          </el-descriptions-item>
          <el-descriptions-item label="金额" span="1">{{ info.attributionAppData.repairAmount / 100 }}
          </el-descriptions-item>
          <el-descriptions-item label="结果" span="1">{{
            info.attributionAppData.repairResponse
            }}
          </el-descriptions-item>
          <el-descriptions-item label="第几天支付" span="2">{{ info.attributionAppData.repairDay + 1 }}
          </el-descriptions-item>
        </template>
        <el-descriptions-item label="支付补报" span="2" v-else>
          <el-button size="small" type="primary" @click="showRepairModel = true">补报</el-button>
        </el-descriptions-item>
        <el-descriptions-item label="" span="2"></el-descriptions-item>
        <template v-if="info.attributionAppData.channelData">
          <el-descriptions-item label="匹配渠道数据" span="2"></el-descriptions-item>
          <el-descriptions-item label="oaid" span="1">{{
            info.attributionAppData.channelData.oaid
            }}
          </el-descriptions-item>
          <el-descriptions-item label="oaidMD5" span="1">{{
            info.attributionAppData.channelData.oaidMD5
            }}
          </el-descriptions-item>
          <el-descriptions-item label="ip" span="1">{{ info.attributionAppData.channelData.ip }}</el-descriptions-item>
          <el-descriptions-item label="ipMD5" span="1">{{
            info.attributionAppData.channelData.ipMD5
            }}
          </el-descriptions-item>
          <el-descriptions-item label="发生时间" span="1">{{
            dateFormat(info.attributionAppData.channelData.timeAt)
            }}
          </el-descriptions-item>
          <el-descriptions-item label="上报时间" span="1">{{
            info.attributionAppData.channelData.created
            }}
          </el-descriptions-item>
        </template>
      </el-descriptions>
    </el-card>
    <el-card header="VIP" shadow="never">
      <el-descriptions border column="4">
        <el-descriptions-item label="会员" span="4">
          <el-tag size="small">{{ vipDateFormat(info.vipEndAt) }}</el-tag>
          <el-tag size="small" type="danger" v-if="!info.vip && info.vipExpiredAt">已过期:{{
            vipDateFormat(info.vipExpiredAt) }}
          </el-tag>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <div style="display: flex;justify-content: center;align-items: center;margin-top: 10px">
      <el-button @click="executeVipExpire">执行VIP过期</el-button>
      <el-button @click="showVipForm = true" :disabled="info.anonymous">赠送VIP</el-button>
      <el-button @click="handleVIPEdit(info)">VIP修改</el-button>
      <!--      <el-button @click="handleEdit(info)">用户信息修改</el-button>-->
      <el-button type="danger" @click="handleDel(info)">删除</el-button>
    </div>
    <el-card style="margin-top: 15px;" header="登录信息" shadow="never">
      <el-table :data="auths" stripe border>
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="app" label="app">
        </el-table-column>
        <el-table-column prop="provider" label="平台">
        </el-table-column>
        <el-table-column prop="openId" label="openId">
        </el-table-column>
        <el-table-column prop="created" label="绑定时间">
          <template #default="{row}">
            <div>{{ row.created }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin-top: 15px;" header="会员记录" shadow="never">
      <el-table :data="vips" stripe border>
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="name" label="用户1">
          <template #default="{row}">
            <router-link v-if="row.userInfo" :to="{name:'manage_user_detail', params:{id:row.userInfo.userId}}"
                         target="_blank">
              {{ row.userInfo.name }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="类型">
          <template #default="{row}">
            {{ typeFormat(row) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="用户2">
          <template #default="{row}">
            <router-link v-if="row.inviterInfo" :to="{name:'manage_user_detail', params:{id:row.inviterInfo.userId}}"
                         target="_blank">
              {{ row.inviterInfo.name }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="增加时间">
          <template #default="{row}">
            {{ row.days}}/{{ vipUnitFormat(row) }}
          </template>
        </el-table-column>
        <el-table-column prop="desc" label="备注">
        </el-table-column>
        <el-table-column prop="created" label="下单时间">
          <template #default="{row}">
            <div>{{ row.created }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin-top: 15px;" header="设备信息" shadow="never">
      <el-descriptions border column="3">
        <el-descriptions-item label="应用">{{ info.uniPlatform }} - {{ info.appName }}
        </el-descriptions-item>
        <el-descriptions-item label="版本">{{ info.appVersionCode }}</el-descriptions-item>
        <el-descriptions-item label="系统">{{ info.osName }}({{ info.osVersion }})</el-descriptions-item>
        <el-descriptions-item label="设备">{{ info.deviceBrand }}({{ info.deviceModel }})</el-descriptions-item>
        <el-descriptions-item label="修改时间" span="2">{{ info.updated }}</el-descriptions-item>
        <el-descriptions-item label="IP归属">{{ info.address }}</el-descriptions-item>
        <el-descriptions-item label="IP">{{ info.ip }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card style="margin-top: 15px;" header="订单" shadow="never">
      <el-table :data="orders" stripe border>
        <el-table-column type="index" width="60"></el-table-column>
        <el-table-column prop="id" label="订单号">
          <template #default="{row}">
            <div>{{ row._id }}</div>
            <div>{{ row.body }}</div>
          </template>
        </el-table-column>
        <el-table-column label="支付金额" width="100">
          <template #default="{row}">
            <div v-if="row.wantFee">{{ row.wantFee / 100 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="transactionId" label="流水号"></el-table-column>
        <el-table-column prop="payAt" label="是否支付">
          <template #default="{row}">
            <div>{{ row.payAt ? dateFormat(row.payAt) : '' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="支付渠道">
          <template #default="{row}">
            <div>{{ payChannelFormat(row) }}</div>
            <div v-if="row.page">
              <div>来源：</div>
              <div>页面：{{ decodeURIComponent(row.pagePath || row.page) }}</div>
              <div>
                上一页：{{
                (row.prevPagePath === 'empty' || !row.prevPagePath) ? '无' : row.prevPagePath
                }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template #default="{row}">
            <div>{{ payStatusFormat(row) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="created" label="下单时间">
          <template #default="{row}">
            <div>{{ row.created }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin-top: 15px;" header="预算信息" shadow="never">
      <el-table :data="budgetList" stripe border>
        <el-table-column type="index" width="60"></el-table-column>
        <el-table-column prop="id" label="金额">
          <template #default="{row}">
            <div v-if="row.amount">{{ row.amount / 100 }}</div>
          </template>
        </el-table-column>
        <el-table-column label="类型" width="100">
          <template #default="{row}">
            {{ row.type === 1 ? '总预算' : '分类预算' }}
          </template>
        </el-table-column>
        <el-table-column label="分类" width="100">
          <template #default="{row}">
            <div v-if="row.categoryInfo">{{ row.categoryInfo.name}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="timeM" label="所属月份">
          <template #default="{row}">
            <div>{{ row.timeY }}年{{ row.timeM }}月</div>
          </template>
        </el-table-column>
        <el-table-column prop="accountInfo" label="来自账本">
          <template #default="{row}">
            <div>{{ row.accountInfo ? row.accountInfo.name: '日常账本'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="created" label="创建时间">
          <template #default="{row}">
            <div>{{ row.created }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin-top: 15px;" header="自定义分类" shadow="never">
      <el-table :data="diffCategories" stripe border>
        <el-table-column type="index" width="60"></el-table-column>
        <el-table-column prop="name" label="名称">
          <template #default="{row}">
            <div>{{ row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column label="类型" width="100">
          <template #default="{row}">
            {{ row.direction === 1 ? '收入' : '支出' }}
          </template>
        </el-table-column>
        <el-table-column prop="accountInfo" label="来自账本">
          <template #default="{row}">
            <div>{{ row.accountInfo.name}}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
      title="用户修改"
      draggable
      v-model="showForm">
      <el-form :model="form">
        <el-form-item label="姓名" :label-width="140">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="密码" :label-width="140">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item label="账号类型" :label-width="140">
          <el-select v-model="form.staff">
            <el-option :value="1" label="员工"></el-option>
            <el-option label="用户"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" :label-width="140">
          <el-select v-model="form.status">
            <el-option v-for="opt in statusOpts" :key="opt.value" :value="opt.value" :label="opt.label"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="showForm = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog
      title="VIP修改"
      draggable
      v-model="showVIPUpdateForm">
      <el-form :model="vipForm">
        <el-form-item label="会员日期" :label-width="140">
          <el-date-picker type="date" v-model="vipForm.vipEndAt" clearable></el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="showVIPUpdateForm = false">取消</el-button>
        <el-button type="primary" @click="handleUpdateVip">提交</el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog
      title="奖励VIP"
      v-model="showVipForm">
      <el-form :model="vipForm">
        <el-form-item label="时长（月）" :label-width="140">
          <el-input v-model="vipForm.months"></el-input>
        </el-form-item>
        <el-form-item label="理由" :label-width="140">
          <el-input v-model="vipForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="showVipForm = false">取消</el-button>
        <el-button type="primary" @click="handleDepositVip">提交</el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog
      title="支付补报"
      draggable
      v-model="showRepairModel">
      <el-form>
        <el-form-item label="金额" :label-width="100">
          <el-input v-model="repairAmount" type="input"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="showRepairModel = false">取消</el-button>
        <el-button type="primary" @click="handleRepairSubmit">提交</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
  <div v-else>{{ error }}</div>
</template>

<script>
  import {userApi, userInfoApi, attributionApi} from '@/api/admin'
  import moment from 'moment'
  import {ElMessage, ElMessageBox} from 'element-plus'

  const defaultMessage = {
    title: null,
    content: null,
    type: null,
    confirmText: null,
    cancelText: null,
    href: null,
    page: null,
    hidePage: null
  }
  export default {
    name: 'UserDetailScreen',
    data() {
      return {
        auths: [],
        vips: [],
        messageTemaplates: [],
        exams: [],
        orders: [],
        info: null,
        error: null,
        showVipForm: false,
        showVIPUpdateForm: false,
        showMsgForm: false,
        form: {},
        vipForm: {},
        message: {},
        payChannelMap: {
          1: 'APP - 微信',
          2: '微信小程序',
          3: 'APP - 支付宝',
          4: 'APP - 苹果',
          5: '抖音小程序'
        },
        vipUnitMap: {
          1: '月',
          2: '天',
          3: '年',
        },
        payStatusOpts: [
          {
            value: 0,
            label: '待支付'
          },
          {
            value: 1,
            label: '已支付'
          },
          {
            value: 2,
            label: '已取消'
          },
          {
            value: 6,
            label: '已退款'
          }
        ],
        statusOpts: [
          {
            value: 0,
            label: '停止'
          },
          {
            value: 1,
            label: '正常'
          }
        ],
        showForm: false,
        sendMsgType: null,
        budgetList: [],
        diffCategories: [],

        repairAmount: 5800,
        showRepairModel: false
      }
    },
    watch: {
      '$route.params.id'(id) {
        if (!id) {
          return
        }
        this.init()
      },
      sendMsgType(id) {
        const found = this.messageTemaplates.find(template => template.id === id)
        if (found) {
          this.message = JSON.parse(JSON.stringify(found))
        } else {
          this.message = JSON.parse(JSON.stringify(defaultMessage))
        }
      }
    },
    async created() {
      await this.init()
    },
    methods: {
      typeFormat(item) {
        if (item.type === 1) {
          return '付费'
        }
        if (item.type === 2) {
          return '邀请'
        }
        if (item.type === 3) {
          return '被邀请'
        }
        if (item.type === 4) {
          return '激活兑换码'
        }
        if (item.type === 1000) {
          return '管理员奖励'
        }
      },
      payChannelFormat(item) {
        return this.payChannelMap[item.channel]
      },
      payStatusFormat({status}) {
        return this.payStatusOpts.find(item => item.value === status).label
      },
      dateFormat(time) {
        return moment(time).format('YYYY-MM-DD HH:mm:ss')
      },
      vipDateFormat(time) {
        if (time) {
          return moment(time).format('YYYY-MM-DD')
        }
        return '无'
      },
      timeFormat(time) {
        const now = moment()
        now.hour(0)
            .minute(0)
            .second(time)
        return now.format('HH:mm:ss')
      },
      async init() {
        try {
          const res = await userInfoApi.userInfo({userId: this.$route.params.id})
          this.info = res.data.data.user
          this.vips = res.data.data.vipList
          this.orders = res.data.data.orderList
          this.auths = res.data.data.authList
          this.budgetList = res.data.data.budgetList
          this.diffCategories = res.data.data.diffCategories
        } catch (e) {
          this.error = e.message
          console.log(e.message)
        }
      },
      async executeVipExpire() {
        await ElMessageBox.alert('确认执行', '将计算该用户VIP是否过期', {
          confirmButtonText: '确认',
          callback: async (action) => {
            if (action === 'confirm') {
              const res = await userInfoApi.executeVipExpire(this.info.userId)
              const count = res.data.data
              if (count) {
                this.init()
              }
              ElMessage({
                type: 'success',
                message: '执行成功，影响' + 1 + '位用户'
              })
            }
          }
        })
      },
      async handleDepositVip() {
        if (!this.info.userId || !this.vipForm.months) {
          return
        }

        await userInfoApi.depositVip(this.info.userId, this.vipForm.months, this.vipForm.desc)
        this.showVipForm = false
        this.init()
      },
      handleEdit(d) {
        console.log(d)
        this.showForm = true
        this.form = JSON.parse(JSON.stringify(d))
      },
      handleVIPEdit(d) {
        console.log(d)
        this.showVIPUpdateForm = true
        this.vipForm = JSON.parse(JSON.stringify(d))
      },
      async handleDel(d) {
        await ElMessageBox.alert('确认用户', '删除用户', {
          confirmButtonText: '确认',
          callback: async (action) => {
            if (action === 'confirm') {
              await userApi.remove(d.id)
              await this.init()
              ElMessage({
                type: 'success',
                message: '删除成功'
              })
            }
          }
        })
      },
      async handleSubmit() {
        if (!this.form.inviteUserId) {
          this.form.inviteUserId = null
        }
        if (this.form.id) {
          await userApi.update(this.form.id, {data: this.form})
        } else {
          await userApi.create(this.form)
        }
        this.showForm = false
        this.showVIPUpdateForm = false
        await this.init()
      },
      async handleUpdateVip() {
        if (!this.info.userId || !this.vipForm.vipEndAt) {
          return
        }

        await userInfoApi.updateVip(this.info.userId, this.vipForm.vipEndAt)
        this.showVIPUpdateForm = false
        this.init()
      },
      async handleRepairSubmit() {
        await attributionApi.uploadRepair(this.info.userId, this.repairAmount)
        this.showRepairModel = false
        this.repairAmount = 5800
        this.init()
      },

      vipUnitFormat(item) {
        return this.vipUnitMap[item.unit]
      },
    }
  }
</script>

<style scoped lang='less'>
  .UserDetailScreen-container {
    .price {
      color: red;
    }

    .green {
      color: green;
    }

    .normal {
      color: #aaa;
    }
  }

  .score {

    .edit {
      display: none;
    }

    &:hover {
      .edit {
        display: inline-flex;
      }
    }
  }
</style>
